<template>
  <Dialog
    :header="
      doyIndex == null ? $t('settings.createDoy') : $t('settings.editDoy')
    "
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '40vw' }"
  >
    <div class="grid grid-nogutter vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0">
        <label for="code">{{ $t("settings.code") }}*:</label>
      </div>
      <div class="field col-12 md:col-6 pt-0 md:pt-3">
        <InputText
          id="code"
          :class="{
            'p-invalid': v$.doy.code.$invalid && submitted,
          }"
          aria-describedby="code-error"
          v-model="v$.doy.code.$model"
          :placeholder="$t('settings.code') + ' *'"
          :showIcon="true"
        />
      </div>
      <ul
        v-if="v$.doy.code.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-3 mt-0"
      >
        <li v-for="(error, index) of v$.doy.code.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("settings.code")) }}
        </li>
      </ul>
    </div>

    <div class="grid grid-nogutter vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0">
        <label for="name">{{ $t("settings.name") }}*:</label>
      </div>
      <div class="field col-12 md:col-6 pt-0 md:pt-3">
        <InputText
          id="name"
          :class="{
            'p-invalid': v$.doy.name.$invalid && submitted,
          }"
          aria-describedby="name-error"
          v-model="v$.doy.name.$model"
          :placeholder="$t('settings.name') + ' *'"
          :showIcon="true"
        />
      </div>
      <ul
        v-if="v$.doy.name.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-3 mt-0"
      >
        <li v-for="(error, index) of v$.doy.name.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("settings.name")) }}
        </li>
      </ul>
    </div>

    <div class="grid">
      <div class="col">
        <span>* {{ $t("global.mandatoryFields") }}</span>
      </div>
    </div>

    <template #footer>
      <Button
        :label="doyIndex == null ? $t('global.create') : $t('global.save')"
        class="p-button-success"
        @click="create"
        autofocus
        :loading="loadingButton"
        iconPos="right"
      />
      <Button
        :label="$t('global.close')"
        @click="close()"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@/utilities/i18n-validators";
import { mapGetters } from "vuex";
export default {
  name: "Add edit doy",
  props: ["display", "doyIndex"],
  emits: ["closeAddEditDoy"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      doy: {
        code: "",
        name: "",
      },
      submitted: false,
      loadingButton: false,
    };
  },
  computed: {
    ...mapGetters(["doyOptions"]),
  },
  watch: {
    display(val) {
      this.viewDialog = val;
      if (this.doyIndex != null) {
        this.doy = JSON.parse(JSON.stringify(this.doyOptions[this.doyIndex]));
      }
    },
    viewDialog(val) {
      if (val == false) {
        this.close();
      } else {
        this.submitted = false;
      }
    },
  },
  validations: {
    doy: {
      code: {
        required,
      },
      name: {
        required,
      },
    },
  },
  methods: {
    clearDoy() {
      this.doy = {
        code: "",
        name: "",
      };
    },
    async create() {
      this.submitted = true;
      const result = await this.v$.$validate();
      if (result) {
        const tempDoyOptions = [...this.doyOptions];
        if (this.doyIndex != null) {
          tempDoyOptions[this.doyIndex] = this.doy;
          this.$store.commit("doy", tempDoyOptions);
          this.close();
        } else {
          this.close(this.doy);
        }
      }
    },
    close(newDoy = null) {
      this.$emit("closeAddEditDoy", newDoy);
      this.clearDoy();
    },
  },
};
</script>
