<template>
  <AddEditDoy
    :display="showAddEditDoy"
    :doyIndex="editDoyIndex"
    @closeAddEditDoy="closeAddEditDoy"
  ></AddEditDoy>
  <BulkImport
    :display="showBulkImport"
    :type="'doy'"
    @closeImport="closeBulkImport"
    @importData="importData"
  ></BulkImport>
  <Card>
    <template #title>
      <div class="grid">
        <div class="col-6 md:col-2">
          <h4>{{ $t("settings.doy") }}</h4>
        </div>
        <div class="text-center text-right col-6 md:col-2">
          <Button
            v-if="!isMobile"
            :label="$t('global.export')"
            @click="exportCSV"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button
            :label="$t('settings.bulkDownload')"
            @click="bulkExport"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button
            :label="$t('settings.bulkAddition')"
            @click="bulkDoy"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-left md:text-right">
          <Button
            :label="$t('settings.addDoy')"
            @click="addDoy"
            class="mr-3"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button :label="$t('global.saveJson')" @click="exportJson"></Button>
        </div>
      </div>
    </template>

    <template #content>
      <DataTable
        :value="doyValues"
        @rowReorder="onRowReorder"
        responsiveLayout="scroll"
        showGridlines
        :loading="loading"
        ref="doyTable"
        @page="changePage"
      >
        <Column
          :rowReorder="true"
          :reorderableColumn="false"
          headerStyle="width: 3rem"
        />
        <Column field="code" :header="$t('settings.code')">
          <template #body="{ data }">
            <div>
              {{ data.code }}
            </div>
          </template>
        </Column>
        <Column field="name" :header="$t('settings.name')">
          <template #body="{ data }">
            <div>
              {{ data.name }}
            </div>
          </template>
        </Column>
        <Column :header="$t('global.actions')">
          <template #body="{ data, index }">
            <div class="flex justify-content-evenly align-items-center">
              <Button
                class="p-button-text mdi mdi-24px mdi-pencil"
                @click="editDoy(index)"
              />
              <Button
                icon="mdi mdi-close-circle mdi-24px"
                class="p-button-rounded p-button-text p-button-text"
                @click="deleteDoy(data, index)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
import AddEditDoy from "./AddEditDoy.vue";
import BulkImport from "../BulkImport.vue";
export default {
  name: "Doy",
  components: {
    AddEditDoy,
    BulkImport,
  },
  data() {
    return {
      doyValues: [],
      showAddEditDoy: false,
      editDoyIndex: null,
      showBulkImport: false,
      loading: false,
      currentPage: 0,
      currentRows: null,
    };
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("doy");
    this.loading = false;
  },
  computed: {
    ...mapGetters(["doyOptions", "isMobile"]),
  },
  watch: {
    doyOptions() {
      this.doyValues = [...this.doyOptions];
    },
  },
  methods: {
    changePage(data) {
      this.currentPage = data.page;
      this.currentRows = data.rows;
    },
    addDoy() {
      this.showAddEditDoy = true;
    },
    closeAddEditDoy(newDoy) {
      this.showAddEditDoy = false;
      this.editDoyIndex = null;
      if (newDoy) {
        this.doyValues.push(newDoy);
        this.$store.commit("doy", this.doyValues);
      } else {
        this.doyValues = this.doyOptions;
      }
    },
    bulkDoy() {
      this.showBulkImport = true;
    },
    closeBulkImport() {
      this.showBulkImport = false;
    },
    onRowReorder(event) {
      console.log(event);
      this.doyValues = [...event.value];
    },
    importData(data) {
      console.log(data);
      this.doyValues = this.doyValues.concat(data);
    },
    async bulkExport() {
      await this.$store.dispatch("bulkExport", "doy");
    },
    async exportJson() {
      this.$store.dispatch("exportJson", {
        name: "doy",
        json: this.doyValues,
      });
    },
    editDoy(index) {
      this.showAddEditDoy = true;
      this.editDoyIndex = index + this.currentPage * this.currentRows;
    },
    deleteDoy(doy, doyIndex) {
      this.$confirm.require({
        message: this.$t("settings.deleteDoy", [doy.name]),
        header: this.$t("settings.deleteConfirmation"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: this.$t("global.yes"),
        rejectLabel: this.$t("global.no"),
        accept: () => {
          this.doyValues.splice(
            doyIndex + this.currentPage * this.currentRows,
            1
          );
          this.$store.commit("doy", this.doyValues);
        },
      });
    },
    exportCSV() {
      this.$refs.doyTable.exportCSV();
    },
  },
};
</script>
